import React from "react";
import classNames from "classnames";
import Image from "../elements/Image";
import css from "./AsSeenIn.module.css";

const AsSeenIn = ({ topDivider, bottomDivider, pushLeft, ...props }) => {
  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props}>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <hr />
          <div className={css.container}>
            <a
              href="https://tracis.io/docs/TracisFDTimes.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image
                src={require("./../../assets/images/Film-and-Digital-Times-Logo.svg")}
                alt="Features split 01"
                width={300}
                className={css.img}
              />
            </a>
            <a
              href="https://www.newsshooter.com/2019/06/03/tracis-lens-scanner/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image
                src={require("./../../assets/images/NS_Logo_WonB.svg")}
                alt="Features split 01"
                width={300}
                className={css.img}
                style={{ marginTop: "20px" }}
              />
            </a>
          </div>
          <hr />
        </div>
      </div>
    </section>
  );
};

export default AsSeenIn;
