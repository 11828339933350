import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Button from "../elements/Button";

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);

  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  const submitEmail = () => {
    if (!validateEmail(email)) return;

    let url =
      "https://api.sheety.co/2cfc28ca69c922249d8a27bd3d385508/emailSignups/sheet1";
    let body = {
      sheet1: {
        email: email,
      },
    };
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json.sheet1);
        setEmailSent(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {emailSent ? (
            <span style={{ color: "white" }}>
              Hooray! Thanks for joining our family! You'll hear from us soon.
              🎉
            </span>
          ) : (
            <>
              <div className="cta-slogan">
                <h3 className="m-0">Join our mailing list today!</h3>
              </div>
              <div className="cta-action" style={{ display: "flex" }}>
                <Input
                  id="newsletter"
                  type="email"
                  label="Subscribe"
                  labelHidden
                  hasIcon="right"
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                ></Input>
                <Button
                  onClick={submitEmail}
                  style={{ marginLeft: "10px" }}
                  disabled={!validateEmail(email)}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
